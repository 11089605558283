<template>
  <v-container fluid>
    <FilterDrawer
      current-component="companies-filter"
      @filter="filterCompanies"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.company_name }}</td>
        <td>{{ item.tranding_name }}</td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
        <td>{{ item.email }}</td>
        <td>{{ item.phone_number }}</td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    StatusChip,
    FilterDrawer,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'Empresas',
      new: 'companies-new',
      edit: 'companies-edit',
      context: 'companies-context',
      massAction: 'companies-mass-action',
      totalPages: 1,
      totalRegister: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        { text: 'Nome', value: 'company_name' },
        { text: 'Nome Fantasia', value: 'tranding_name' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Email', value: 'email', align: 'center' },
        { text: 'Telefone', value: 'phone_number', align: 'center' },
      ],
    },
  }),
  mounted: function () {
    this.getCompanies()

    contextMenu.$once('action', (type) => {
      if (type === 'companies') {
        this.getCompanies()
      }
    })
    massAction.$once('action', () => {
      this.getCompanies()
    })
  },
  methods: {
    setCurrentPage(page) {
      this.getCompanies(page)
    },
    search(search) {
      this.currentSearch = search
      this.getCompanies()
    },
    getCompanies(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('companies', {
          params: {
            page,
            search: this.currentSearch,
          }
        })
        .then((res) => {
          this.dataTable.items = res.data.data.data
          this.dataTable.totalPages = res.data.total_pages;
          this.dataTable.totalRegister = res.data.total_companies
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterCompanies(obj) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('companies/filter', obj)
        .then((res) => {
          this.dataTable.items = res.data.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getCompanies()
    },
  },
}
</script>
